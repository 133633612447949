import { Injectable } from '@angular/core';
import { Elurisoft } from './elurisoft';

@Injectable()
export class Account {

	public userDetails: any = {};
	public cntUnreadThreads: number = 0;
	public cntUnreadAdminRepliesThreads: number = 0;
	public adminUsers: any = [];
	public isAcceptedTermsAndConditions: boolean = true;

	constructor(private elurisoft: Elurisoft) { }

	initialize() {
		return new Promise((resolve, reject) => {
			this.elurisoft.platform.ready().then(() => {
				this.elurisoft.storage.get('userDetails').then((userDetails) => {
					if (userDetails && userDetails.auth_token) {
						this.userDetails = userDetails;
					}
					resolve(true);
				}).catch(error => {
					resolve(true);
				});
			});
		});
	}

	verifyAuthToken(userID: number, authToken: string) {
		return new Promise((resolve, reject) => {
			const reqParams: any = {};
			reqParams.user_id = userID;
			reqParams.auth_token = authToken;
			this.elurisoft.makeHttpRequest('account/validate_auth_token', reqParams).then((response) => {
				if (response.success === 1) {
					resolve(response);
				} else {
					reject(false);
				}
			}).catch((httpError) => {
				reject(false);
			});
		});
	}

	getAdminUsers() {
		const requestParams: any = {};
		requestParams.user_id = this.userDetails.id;
		requestParams.auth_token = this.userDetails.auth_token;
		requestParams.type = 'admin';
		this.elurisoft.makeHttpRequest('common/get_users', requestParams).then(async (result) => {
			this.elurisoft.hideLoader();
			if (result.success === 1) {
				this.adminUsers = result.data.users;
			} else if (result.error === 1) {
				if (result.errorCode === 1 || result.errorCode === 2) {
					this.elurisoft.toast(this.elurisoft.config.defaultAuthErrorTitle, this.elurisoft.config.defaultAuthErrorMessage);
					this.doLogout();
				} else if (result.errorCode === 3) {
					this.elurisoft.toast(this.elurisoft.config.defaultAuthErrorTitle, 'No Admin users found.');
				} else {
					this.elurisoft.toast(this.elurisoft.config.defaultErrorTitle, 'API Error: ' + this.elurisoft.config.defaultErrorMsg);
				}
			} else {
				this.elurisoft.toast(this.elurisoft.config.defaultErrorTitle, 'API Error: ' + this.elurisoft.config.defaultErrorMsg);
			}
		}).catch(async (error) => {
			this.elurisoft.hideLoader();
			this.elurisoft.toast(this.elurisoft.config.defaultErrorTitle, 'API Error: ' + this.elurisoft.config.defaultErrorMsg);
		});
	}

	async doLogout() {
		if (!this.userDetails || (this.userDetails && !this.userDetails.auth_token)) {
			this.userDetails = await this.elurisoft.storage.get('userDetails');
		}
		if (this.userDetails.auth_token && this.userDetails.id) {
			await this.elurisoft.showLoader();
			const requestParams: any = {};
			requestParams.user_id = this.userDetails.id;
			requestParams.auth_token = this.userDetails.auth_token;
			this.elurisoft.makeHttpRequest('account/logout', requestParams).then(async (result) => {
				if (result.success === 1) {
					this.elurisoft.storage.clear();
					this.userDetails = {};
				} else if (result.error === 1) {
					if (result.errorCode === 1 || result.errorCode === 2) {
						this.elurisoft.storage.clear();
						this.userDetails = {};
					} else {
						this.elurisoft.toast('Logout Failed!', 'API_ERROR ' + result.errorCode + ' : ' + this.elurisoft.config.defaultErrorMsg);
					}
				}
				else {
					this.elurisoft.toast('Logout Failed!', 'API_ERROR ' + result.errorCode + ' : ' + this.elurisoft.config.defaultErrorMsg);
				}
				await this.elurisoft.hideLoader();
				this.elurisoft.navigate('/login');
			}).catch(async (httpError) => {
				await this.elurisoft.hideLoader();
				this.elurisoft.toast('Logout Failed!', 'CONNECTIVITY_ERROR : ' + httpError.errorMessage);
				this.elurisoft.navigate('/login');
			});
		} else {
			this.elurisoft.navigate('/login');
		}
	}
}